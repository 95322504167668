import type { BlockTypes } from '@etf1-interne/tf1info_types_news'
import { useContext, useEffect, useId, useState } from 'react'
import slugify from 'slugify'
import { ApiResponseContext } from '../../../contexts/ApiResponseContext'
import { SVGIcon } from '../SVGIcon'
import { mixins, theme } from '../../../styles'
import { useHeaderHeight } from '../../../hook/useHeaderHeight'
import { MENU_SECONDARY_ID } from '../../../constants/components'
import { HOME_URLS } from '../../../constants/home'
import { TagCommanderContext } from '../../../contexts/tagcommander'
import { useViewport } from '../../../hook/useViewport'

export interface IMenuNavigation {
  elementList: BlockTypes['header-navigation']['data']['navigation']
  isSticky?: boolean
  activeMenuId?: string
}

const HEADER_PADDING = 16
const HEADER_BORDER = 5

function MenuNavigationItem({
  menu,
  index,
  isHighlight,
  onMouseEnter,
  isSubMenuOpen,
  toggleDropDown,
}: {
  menu: BlockTypes['header-navigation']['data']['navigation'][number]
  index: number
  isHighlight: boolean
  onMouseEnter: () => void
  isSubMenuOpen: boolean
  toggleDropDown: () => any
}) {
  const { hit } = useContext(TagCommanderContext)
  const controlId = useId()
  const { page } = useContext(ApiResponseContext)
  const { viewport } = useViewport()
  const headerHeight = useHeaderHeight()
  const isMenuInHeader = viewport >= theme.breakpoints.lg
  const hasDropDown = menu?.elementList?.length && isMenuInHeader

  const ItemComponent = hasDropDown ? 'button' : isHighlight ? 'span' : 'a'
  const itemProps = hasDropDown
    ? {
        onClick: () => {
          toggleDropDown()
          handleTmsClick(menu.title)
        },
        'aria-expanded': isSubMenuOpen,
        'aria-controls': controlId,
      }
    : isHighlight
    ? {}
    : { href: menu.link, onClick: () => handleTmsClick(menu.title) }

  function renderItemContent() {
    return (
      <>
        {menu.picto ? (
          <SVGIcon
            name={menu.picto}
            primaryColor={theme.cssVars.white}
            secondaryColor={menu.color}
          />
        ) : null}
        <span>{menu.title}</span>
        {menu?.elementList?.length ? (
          <SVGIcon primaryColor={theme.cssVars.white} name="chevron" orientation="down" />
        ) : null}
      </>
    )
  }

  function handleTmsClick(label: string, subLabel?: string) {
    const subTitle = subLabel
      ? `_${slugify(subLabel, {
          lower: true,
        })}`
      : ''

    hit(
      {
        screen_clickableElementName: `${isMenuInHeader ? 'header_' : ''}tab-bar_${slugify(label, {
          lower: true,
        })}${subTitle}`,
      },
      { isClickEvent: true },
    )
  }

  return (
    <>
      <li
        className={[
          'MenuNavigationItem flex',
          isHighlight ? 'MenuNavigationItem--highlight' : '',
        ].join(' ')}
        aria-current={isHighlight ? 'page' : undefined}
        onMouseEnter={onMouseEnter}
        id={`menu-item-${index}`}
      >
        <ItemComponent
          {...itemProps}
          className="MenuNavigationItem__Link flex flex-column justify-center items-center"
        >
          {renderItemContent()}
        </ItemComponent>
        {hasDropDown ? (
          <>
            <ul
              id={controlId}
              className={[
                'MenuNavigationItem__Dropdown flex flex-column',
                isSubMenuOpen ? 'MenuNavigationItem__Dropdown--open' : '',
              ].join(' ')}
            >
              {menu.elementList.map((submenu, index) => (
                <li key={index} aria-current={isHighlight ? 'true' : undefined}>
                  {submenu?.link === page.url ? (
                    <span>{submenu.title}</span>
                  ) : (
                    <a
                      href={submenu?.link}
                      onClick={() => handleTmsClick(menu.title, submenu.title)}
                      className="MenuNavigationItem__Dropdown__Link"
                    >
                      {submenu.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </>
        ) : null}
      </li>
      <style jsx>{`
        @media (max-width: ${theme.breakpoints.lg}px) {
          .MenuNavigationItem {
            opacity: ${isHighlight ? 1 : 0.4};
          }
        }
      `}</style>
      <style jsx>{`
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .MenuNavigationItem__Link {
          font-family: ${theme.cssVars.overpass};
          color: ${theme.colors.white};
        }

        .MenuNavigationItem__Link:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 5;
        }

        .MenuNavigationItem__Dropdown__Link {
            display: inline-block;
        }

        @media (max-width: ${theme.breakpoints.lg}px) {
          .MenuNavigationItem {
            cursor: pointer;
            position: relative;
            display: grid;
            grid-auto-flow: column;
            width: 65px;
            gap: 4px;
            flex-shrink: 0;
          }

          .MenuNavigationItem:hover {
            opacity: 1;
          }

          .MenuNavigationItem * {
            text-align: center;
            white-space: nowrap;
            gap: 4px;
          }

          .MenuNavigationItem__Link {
            font-size: 11px;
            font-style: normal;
            font-weight: 600;
            line-height: 130%;
            text-decoration: none;
          }

          .MenuNavigationItem__Link :global(svg) {
            display: block;
          }

          .MenuNavigationItem__Link :global(svg:nth-child(3)) {
            display: none;
          }

          .MenuNavigationItem__Link :global(span) {
            text-decoration: none;
          }

          .MenuNavigationItem__Dropdown {
            display: none;
          }

          button.MenuNavigationItem__Link {
            background: transparent;
            border: none;
            padding: 0;
          }
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .MenuNavigationItem--highlight {
            background-color: ${theme.colors.darkBlue};
          }

          .MenuNavigationItem {
            position: relative;
            padding: 0 8px;
            border-radius: 8px;
            width: 100%;
            opacity: 1;
          }

          .MenuNavigationItem :global(svg) {
            min-width: 24px;
          }

          .MenuNavigationItem__Link {
            cursor: pointer;
            background: transparent;
            border: none;
            padding-top: 2px;
            gap: 6px;
            width: 100%;
            display: grid;
            grid-auto-flow: column;
            max-height: 32px;

            font-size: 11px;
            font-weight: 900;
            text-transform: uppercase;
            padding: 0;
          }

          .MenuNavigationItem__Link :global(svg) {
            margin-top: -2px;
          }

          .MenuNavigationItem__Link :global(svg:nth-child(3)) {
            display: block;
          }

          .MenuNavigationItem__Dropdown {
            --paddingMenuNavigationItem__Dropdown: 16px;
            --gapMenuNavigationItem__Dropdown: 24px;
            position: absolute;
            top: calc(100% + ${headerHeight / 2 - HEADER_PADDING}px);
            left: 0;
            display: grid;
            min-width: 100%;

            list-style: none;
            font-size: 15px;
            color: ${theme.cssVars.white};
            background: ${theme.cssVars.headerBackground};
            border: 1px solid ${theme.cssVars.lightGrayBlueOpacity};
            padding: var(--paddingMenuNavigationItem__Dropdown);
            gap: var(--gapMenuNavigationItem__Dropdown);
          }

          .MenuNavigationItem__Dropdown:not(.MenuNavigationItem__Dropdown--open) {
            display: none;
            visibility: hidden;
          }

          .MenuNavigationItem__Dropdown--open {
            animation: fadeIn 350ms;
          }

          .MenuNavigationItem__Dropdown--open li {
            position: relative;
          }

          .MenuNavigationItem__Dropdown--open li:hover {
            z-index: 1;
          }

          .MenuNavigationItem__Dropdown--open li a:before {
            content: '';
            position: absolute;
            top: calc(var(--gapMenuNavigationItem__Dropdown) / 2 * -1);
            left: calc(var(--paddingMenuNavigationItem__Dropdown) * -1 - 1px);
            width: calc(100% + var(--paddingMenuNavigationItem__Dropdown) * 2 + 2px);
            height: calc(100% + var(--gapMenuNavigationItem__Dropdown));
            display: inline;
          }

          .MenuNavigationItem__Dropdown--open li:not(:last-child) a:before {
            border: 0px;
            border-bottom: 1px solid #ffffff1a;
          }

          .MenuNavigationItem__Dropdown--open li:last-child a:before {
            height: calc(100% + var(--gapMenuNavigationItem__Dropdown) + 5px);
          }

          .MenuNavigationItem__Dropdown--open li:hover a:before {
            z-index: -1;
            background-color: ${theme.cssVars.darkBlue};
          }

          .MenuNavigationItem__Dropdown span {
            font-weight: 800;
          }
        }

        @media ${mixins.mediaQuery.desktop} {
          .MenuNavigationItem__Link {
            gap: 8px;
          }

          .MenuNavigationItem__Link {
            font-size: 15px;
          }
        }
      `}</style>
    </>
  )
}

export function MenuNavigation({
  elementList,
  isSticky,
  activeMenuId,
}: IMenuNavigation): JSX.Element {
  const { page } = useContext(ApiResponseContext)
  const currentMenuIndex = elementList.findIndex((menu) => menu.id === activeMenuId)
  const [hoveredTab, setHoveredTab] = useState<number | null>(null)
  const [borderPosition, setBorderPosition] = useState(0)
  const [borderWidth, setBorderWidth] = useState(0)
  const [isSubMenuOpen, setIsSubMenuOpen] = useState()

  const { viewport } = useViewport()

  const isMenuNavigationInHeader = viewport >= theme.breakpoints.lg
  const isHomePage = HOME_URLS.includes(page.url)

  const currentBorderColor =
    elementList[hoveredTab]?.color ?? elementList[currentMenuIndex]?.color ?? theme.colors.lightBlue

  function toggleDropDown(index) {
    return () => setIsSubMenuOpen(index === isSubMenuOpen ? null : index)
  }

  useEffect(() => {
    if (hoveredTab === null && isMenuNavigationInHeader) {
      setHoveredTab(currentMenuIndex)
    }
  }, [currentMenuIndex, hoveredTab, isMenuNavigationInHeader])

  useEffect(() => {
    if (isMenuNavigationInHeader) {
      setBorderPosition(document.getElementById(`menu-item-${hoveredTab}`)?.offsetLeft ?? 0)
      setBorderWidth(document.getElementById(`menu-item-${hoveredTab}`)?.offsetWidth ?? 0)
    }
  }, [isSticky, hoveredTab, isMenuNavigationInHeader])

  if (!elementList?.length) {
    return null
  }

  return (
    <>
      <nav
        role="navigation"
        aria-label="Menu tf1info"
        className="MenuNavigation"
        data-testid="header-nav-btn"
        id={MENU_SECONDARY_ID}
        tabIndex={-1}
        onMouseLeave={() => setHoveredTab(currentMenuIndex)}
        data-position={isMenuNavigationInHeader ? 'header' : 'footer'}
      >
        {isMenuNavigationInHeader ? <div className="MenuNavigation__Border" /> : null}
        <ul className="MenuNavigation__List flex flex-no-wrap justify-evenly">
          {elementList.map((menu, index) => {
            const isHighlight = index === currentMenuIndex
            return (
              <MenuNavigationItem
                key={index}
                index={index}
                menu={menu}
                isHighlight={isHighlight}
                onMouseEnter={() => setHoveredTab(index)}
                toggleDropDown={toggleDropDown(index)}
                isSubMenuOpen={isSubMenuOpen === index}
              />
            )
          })}
        </ul>
      </nav>

      <style jsx>{`
        .MenuNavigation__Border {
          background-color: ${currentBorderColor};
          left: ${borderPosition}px;
          width: ${borderWidth}px;
        }
      `}</style>
      <style jsx>{`
        .MenuNavigation {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          overflow: hidden;
          border-top: 1px solid ${theme.cssVars.darkBlue};
          background: ${theme.cssVars.blurDarkestBlue};
          backdrop-filter: blur(5px);
          z-index: 50;
          padding: 12px 16px 24px 16px;
        }

        .MenuNavigation__List {
          display: grid;
          grid-auto-flow: column;
          list-style: none;
          margin: 0;
          padding: 0;
          height: 100%;
        }

        @media ${mixins.mediaQuery.tabletPaysage} {
          .MenuNavigation {
            position: initial;
            display: block;
            height: 100%;
            width: 100%;
            overflow: initial;
            bottom: initial;
            border-top: none;
            background: transparent;
            backdrop-filter: none;
            padding: 0;
          }

          .MenuNavigation__List {
            gap: 8px;
            justify-content: flex-start;
          }
        }

        @keyframes fadeInBorder {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }

        .MenuNavigation__Border {
          position: absolute;
          bottom: -${(isHomePage ? HEADER_PADDING : 0) + HEADER_BORDER}px;
          height: 5px;
          z-index: 2;
          transition: all 0.3s ease;
          animation: fadeInBorder 1s;
        }
      `}</style>
    </>
  )
}
